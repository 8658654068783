export const FETCH_USER_DATA_BEGIN   = 'FETCH_USER_DATA_BEGIN';
export const FETCH_USER_DATA_SUCCESS = 'FETCH_USER_DATA_SUCCESS';
export const FETCH_BREEDER_DATA_BEGIN   = 'FETCH_BREEDER_DATA_BEGIN';
export const FETCH_BREEDER_DATA_SUCCESS = 'FETCH_BREEDER_DATA_SUCCESS';

export const FETCH_COMMON_STATES_BEGIN = 'FETCH_COMMON_STATES_BEGIN';
export const FETCH_COMMON_STATES_SUCCESS = 'FETCH_COMMON_STATES_SUCCESS';
export const FETCH_COMMON_POSTS_BEGIN = 'FETCH_COMMON_POSTS_BEGIN';
export const FETCH_COMMON_POSTS_SUCCESS = 'FETCH_COMMON_POSTS_SUCCESS';
export const FETCH_COMMON_REVIEWS_BEGIN = 'FETCH_COMMON_REVIEWS_BEGIN';
export const FETCH_COMMON_REVIEWS_SUCCESS = 'FETCH_COMMON_REVIEWS_SUCCESS';
export const FETCH_COMMON_BLOGS_BEGIN = 'FETCH_COMMON_BLOGS_BEGIN';
export const FETCH_COMMON_BLOGS_SUCCESS = 'FETCH_COMMON_BLOGS_SUCCESS';
export const FETCH_COMMON_BLOG_BEGIN = 'FETCH_COMMON_BLOG_BEGIN';
export const FETCH_COMMON_BLOG_SUCCESS = 'FETCH_COMMON_BLOG_SUCCESS';

export const FETCH_COMMON_MAKEMODEL_BEGIN = 'FETCH_COMMON_MAKEMODEL_BEGIN';
export const FETCH_COMMON_MAKEMODEL_SUCCESS = 'FETCH_COMMON_MAKEMODEL_SUCCESS';

export const FETCH_COMMON_HOMEPAGE_BEGIN = 'FETCH_COMMON_HOMEPAGE_BEGIN';
export const FETCH_COMMON_HOMEPAGE_SUCCESS = 'FETCH_COMMON_HOMEPAGE_SUCCESS';


export const FETCH_COMMON_PAGE_BEGIN = 'FETCH_COMMON_PAGE_BEGIN';
export const FETCH_COMMON_PAGE_SUCCESS = 'FETCH_COMMON_PAGE_SUCCESS';

export const FETCH_COMMON_PAGES_BEGIN = 'FETCH_COMMON_PAGES_BEGIN';
export const FETCH_COMMON_PAGES_SUCCESS = 'FETCH_COMMON_PAGES_SUCCESS';

export const FETCH_COMMON_FAQS_BEGIN = 'FETCH_COMMON_FAQS_BEGIN';
export const FETCH_COMMON_FAQS_SUCCESS = 'FETCH_COMMON_FAQS_SUCCESS';
export const FETCH_COMMON_FAQ_BEGIN = 'FETCH_COMMON_FAQ_BEGIN';
export const FETCH_COMMON_FAQ_SUCCESS = 'FETCH_COMMON_FAQ_SUCCESS';

export const FETCH_COMMON_LEADS_BEGIN = 'FETCH_COMMON_LEADS_BEGIN';
export const FETCH_COMMON_LEADS_SUCCESS = 'FETCH_COMMON_LEADS_SUCCESS';
export const FETCH_COMMON_QUERIES_BEGIN = 'FETCH_COMMON_QUERIES_BEGIN';
export const FETCH_COMMON_QUERIES_SUCCESS = 'FETCH_COMMON_QUERIES_SUCCESS';

export const FETCH_COMMON_SETTINGS_BEGIN = 'FETCH_COMMON_SETTINGS_BEGIN';
export const FETCH_COMMON_SETTINGS_SUCCESS = 'FETCH_COMMON_SETTINGS_SUCCESS';


export const FETCH_COMMON_TRANSACTION_BEGIN = 'FETCH_COMMON_TRANSACTION_BEGIN';
export const FETCH_COMMON_TRANSACTION_SUCCESS = 'FETCH_COMMON_TRANSACTION_SUCCESS';

export const FETCH_CARS_DATA_BEGIN   = 'FETCH_CARS_DATA_BEGIN';
export const FETCH_CARS_DATA_SUCCESS   = 'FETCH_CARS_DATA_SUCCESS';

export const FETCH_CARS_DATA_BEGIN_NEW   = 'FETCH_CARS_DATA_BEGIN_NEW';
export const FETCH_CARS_DATA_SUCCESS_NEW   = 'FETCH_CARS_DATA_SUCCESS_NEW';

export const FETCH_SINGLE_CAR_DATA_BEGIN   = 'FETCH_SINGLE_CAR_DATA_BEGIN';
export const FETCH_SINGLE_CAR_DATA_SUCCESS   = 'FETCH_SINGLE_CAR_DATA_SUCCESS';

export const FETCH_FILTERED_CARS_BEGIN   = 'FETCH_FILTERED_CARS_BEGIN';
export const FETCH_FILTERED_CARS_SUCCESS   = 'FETCH_FILTERED_CARS_SUCCESS';
export const FETCH_FILTERED_CARS_BEGIN_NEW   = 'FETCH_FILTERED_CARS_BEGIN_NEW';
export const FETCH_FILTERED_CARS_SUCCESS_NEW   = 'FETCH_FILTERED_CARS_SUCCESS_NEW';

export const FETCH_FILTERS_DATA_BEGIN   = 'FETCH_FILTERS_DATA_BEGIN';
export const FETCH_FILTERS_DATA_SUCCESS   = 'FETCH_FILTERS_DATA_SUCCESS';
export const FETCH_FILTERS_DATA_BEGIN_NEW   = 'FETCH_FILTERS_DATA_BEGIN_NEW';
export const FETCH_FILTERS_DATA_SUCCESS_NEW   = 'FETCH_FILTERS_DATA_SUCCESS_NEW';

export const FETCH_FILTERS_MODEL_DATA_BEGIN   = 'FETCH_FILTERS_MODEL_DATA_BEGIN';
export const FETCH_FILTERS_MODEL_DATA_SUCCESS   = 'FETCH_FILTERS_MODEL_DATA_SUCCESS';

export const FETCH_FILTERS_BODYDTYLE_DATA_BEGIN   = 'FETCH_FILTERS_BODYDTYLE_DATA_BEGIN';
export const FETCH_FILTERS_BODYDTYLE_DATA_SUCCESS   = 'FETCH_FILTERS_BODYDTYLE_DATA_SUCCESS';


export const FETCH_FILTERS_DATA_BEGIN_YEAR   = 'FETCH_FILTERS_DATA_BEGIN_YEAR';
export const FETCH_FILTERS_DATA_SUCCESS_YEAR   = 'FETCH_FILTERS_DATA_SUCCESS_YEAR';
export const FETCH_FILTERS_DATA_BEGIN_MAKE   = 'FETCH_FILTERS_DATA_BEGIN_MAKE';
export const FETCH_FILTERS_DATA_SUCCESS_MAKE   = 'FETCH_FILTERS_DATA_SUCCESS_MAKE';
export const FETCH_FILTERS_DATA_BEGIN_MODEL   = 'FETCH_FILTERS_DATA_BEGIN_MODEL';
export const FETCH_FILTERS_DATA_SUCCESS_MODEL   = 'FETCH_FILTERS_DATA_SUCCESS_MODEL';
export const FETCH_FILTERS_DATA_BEGIN_FUEL   = 'FETCH_FILTERS_DATA_BEGIN_FUEL';
export const FETCH_FILTERS_DATA_SUCCESS_FUEL   = 'FETCH_FILTERS_DATA_SUCCESS_FUEL';
export const FETCH_FILTERS_DATA_BEGIN_BODYSTYLE   = 'FETCH_FILTERS_DATA_BEGIN_BODYSTYLE';
export const FETCH_FILTERS_DATA_SUCCESS_BODYSTYLE   = 'FETCH_FILTERS_DATA_SUCCESS_BODYSTYLE';
export const FETCH_FILTERS_DATA_BEGIN_CONDITION   = 'FETCH_FILTERS_DATA_BEGIN_CONDITION';
export const FETCH_FILTERS_DATA_SUCCESS_CONDITION   = 'FETCH_FILTERS_DATA_SUCCESS_CONDITION';
export const FETCH_FILTERS_DATA_BEGIN_MILEAGE   = 'FETCH_FILTERS_DATA_BEGIN_MILEAGE';
export const FETCH_FILTERS_DATA_SUCCESS_MILEAGE   = 'FETCH_FILTERS_DATA_SUCCESS_MILEAGE';
export const FETCH_FILTERS_DATA_BEGIN_TRANSMISSION   = 'FETCH_FILTERS_DATA_BEGIN_TRANSMISSION';
export const FETCH_FILTERS_DATA_SUCCESS_TRANSMISSION   = 'FETCH_FILTERS_DATA_SUCCESS_TRANSMISSION';
export const FETCH_FILTERS_DATA_BEGIN_ENGINE   = 'FETCH_FILTERS_DATA_BEGIN_ENGINE';
export const FETCH_FILTERS_DATA_SUCCESS_ENGINE   = 'FETCH_FILTERS_DATA_SUCCESS_ENGINE';
export const FETCH_FILTERS_DATA_BEGIN_COLOR   = 'FETCH_FILTERS_DATA_BEGIN_COLOR';
export const FETCH_FILTERS_DATA_SUCCESS_COLOR   = 'FETCH_FILTERS_DATA_SUCCESS_COLOR';