import React, { Component } from "react";
import { BrowserRouter, HashRouter, Switch, Link } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";
import { history } from "./store/helpers/history";
import EmptyLayout from "./layout/EmptyLayout";
import CompleteEmptyLayout from "./layout/CompleteEmptyLayout";
import AdminLayout from "./layout/AdminLayout";
import InnerLayout from "./layout/InnerLayout";
import { RouteWithLayout } from "./layout/RouteWithLayout";
import UsedCars from "./components/UsedCars";
import UsedCarsInProcess from "./components/UsedCarsInProcess";
import HomePage from "./components/HomePage";
import About from "./components/About";
import Blog from "./components/Blog";
import Faq from "./components/Faq";
import TermCondition from "./components/TermCondition";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Contact from "./components/Contact";
import Payment from "./components/Payment";
// import SingleVehicle from './components/SingleVehicle';
import SingleCar from "./components/SingleCar";
import SingleCarnew from "./components/SingleCarnew";
import HttpsRedirect from "react-https-redirect";
import Dashboard from "./admincomponents/Dashboard";
// import AdminVehicleList from './admincomponents/AdminVehicleListnew';
import AdminLeadList from "./admincomponents/AdminLeadList";
import AdminQueriesList from "./admincomponents/AdminQueriesList";
import AdminTransactions from "./admincomponents/AdminTransactions";
import AdminSettings from "./admincomponents/AdminSettings";
// import AdminSingleVehicle from './admincomponents/AdminSingleVehicle';
import AdminBlogs from "./admincomponents/AdminBlogs";
import AdminPages from "./admincomponents/AdminPages";
import AddBlog from "./admincomponents/AddBlog";
import EditBlog from "./admincomponents/EditBlog";
import EditPage from "./admincomponents/EditPage";
import AdminFaqs from "./admincomponents/AdminFaqs";
import AddFaq from "./admincomponents/AddFaq";
import EditFaq from "./admincomponents/EditFaq";
import SignIn from "./components/SignIn";
import Signup from "./components/SignUp";
import parseJwt from "./store/helpers/common";
import DealerSignup from "./components/DealerSignup";
import DealerTransactions from "./admincomponents/DealerTransactions";
import CarSourcing from "./components/CarSourcing";
import CarSourcingTransactions from "./admincomponents/CarSourcingTransactions";
import Thankyou from "./components/Thankyou";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userrole: "",
      is_loggedin: false,
    };
  }
  componentDidMount() {
    if (localStorage.getItem("token")) {
      const currdetails = parseJwt(localStorage.getItem("token"));
      // console.log(currdetails)
      var role = currdetails.urxrs;
      this.setState({ userrole: role, is_loggedin: true });
    }
  }
  render() {
    const { userrole, is_loggedin } = this.state;
    return (
      <HttpsRedirect>
        <BrowserRouter history={history}>
          <ScrollToTop>
            {is_loggedin ? (
              userrole ? (
                userrole === "$aHF667#79+57h%45" ? (
                  <Switch>
                    <RouteWithLayout
                      layout={AdminLayout}
                      path="/used-cars/:location?/:brand?/:model?"
                      component={UsedCars}
                    />
                    {/* <RouteWithLayout layout={AdminLayout} path="/used-cars2/:location?/:brand?/:model?" component={UsedCars} /> */}
                    <RouteWithLayout
                      layout={AdminLayout}
                      path="/leads"
                      component={AdminLeadList}
                    />
                    <RouteWithLayout
                      layout={AdminLayout}
                      path="/queries"
                      component={AdminQueriesList}
                    />
                    <RouteWithLayout
                      layout={AdminLayout}
                      path="/vrt-transactions"
                      component={AdminTransactions}
                    />
                    <RouteWithLayout
                      layout={AdminLayout}
                      path="/dealer-transactions"
                      component={DealerTransactions}
                    />
                    <RouteWithLayout
                      layout={AdminLayout}
                      path="/carsourcing-transactions"
                      component={CarSourcingTransactions}
                    />
                    <RouteWithLayout
                      layout={AdminLayout}
                      path="/settings"
                      component={AdminSettings}
                    />
                    {/* <RouteWithLayout layout={AdminLayout} path="/vehicle/:id" component={AdminSingleVehicle} /> */}
                    <RouteWithLayout
                      layout={AdminLayout}
                      path="/pages"
                      component={AdminPages}
                    />
                    <RouteWithLayout
                      layout={AdminLayout}
                      path="/blogs"
                      component={AdminBlogs}
                    />
                    <RouteWithLayout
                      layout={AdminLayout}
                      path="/add-blog"
                      component={AddBlog}
                    />
                    <RouteWithLayout
                      layout={AdminLayout}
                      path="/edit-blog/:slug"
                      component={EditBlog}
                    />
                    <RouteWithLayout
                      layout={AdminLayout}
                      path="/edit-page/:slug"
                      component={EditPage}
                    />
                    <RouteWithLayout
                      layout={AdminLayout}
                      path="/faqs"
                      component={AdminFaqs}
                    />
                    <RouteWithLayout
                      layout={AdminLayout}
                      path="/add-faq"
                      component={AddFaq}
                    />
                    <RouteWithLayout
                      layout={AdminLayout}
                      path="/edit-faq/:slug"
                      component={EditFaq}
                    />
                    <RouteWithLayout
                      layout={AdminLayout}
                      path="/dashboard"
                      component={Dashboard}
                    />
                    <RouteWithLayout
                      exact={true}
                      layout={EmptyLayout}
                      path="/"
                      component={HomePage}
                    />
                    <RouteWithLayout
                      layout={EmptyLayout}
                      path="/faq"
                      component={Faq}
                    />
                    <RouteWithLayout
                      layout={EmptyLayout}
                      path="/about-us"
                      component={About}
                    />
                    <RouteWithLayout
                      layout={EmptyLayout}
                      path="/blog/:slug?"
                      component={Blog}
                    />
                    <RouteWithLayout
                      layout={EmptyLayout}
                      path="/contact"
                      component={Contact}
                    />
                    <RouteWithLayout
                      layout={EmptyLayout}
                      path="/terms-and-conditions"
                      component={TermCondition}
                    />
                    <RouteWithLayout
                      layout={EmptyLayout}
                      path="/privacy-policy"
                      component={PrivacyPolicy}
                    />
                    <RouteWithLayout
                      layout={EmptyLayout}
                      path="/car/:id"
                      component={SingleCarnew}
                    />
                    <RouteWithLayout
                      layout={EmptyLayout}
                      path="/car-sourcing"
                      component={CarSourcing}
                    />
                    {/* <RouteWithLayout layout={EmptyLayout} path="/dealer-signup" component={DealerSignup} /> */}
                    <RouteWithLayout
                      layout={EmptyLayout}
                      path="/thankyou"
                      component={Thankyou}
                    />
                    {/* <RouteWithLayout layout={EmptyLayout} path="/single-vehicle/:id" component={SingleVehicle} /> */}
                    {/* <RouteWithLayout layout={EmptyLayout} path="/registration" component={Registration} /> */}
                    {/* <RouteWithLayout layout={EmptyLayout} path="/vehicle-lists" component={VehicleList} /> */}
                    {/* <RouteWithLayout layout={EmptyLayout} path="/used-cars/:location?/:brand?/:model?" component={UsedCars} />       */}
                    {/* <RouteWithLayout layout={EmptyLayout} path="/vehicle-lists-new" component={VehicleListnew} /> */}
                  </Switch>
                ) : userrole === "#u$gF4543*70&78h7" ? (
                  <Switch>
                    <RouteWithLayout
                      exact={true}
                      layout={EmptyLayout}
                      path="/"
                      component={HomePage}
                    />
                    <RouteWithLayout
                      layout={EmptyLayout}
                      path="/faq"
                      component={Faq}
                    />
                    <RouteWithLayout
                      layout={EmptyLayout}
                      path="/about-us"
                      component={About}
                    />
                    <RouteWithLayout
                      layout={EmptyLayout}
                      path="/blog/:slug?"
                      component={Blog}
                    />
                    <RouteWithLayout
                      layout={EmptyLayout}
                      path="/contact"
                      component={Contact}
                    />
                    <RouteWithLayout
                      layout={EmptyLayout}
                      path="/terms-and-conditions"
                      component={TermCondition}
                    />
                    <RouteWithLayout
                      layout={EmptyLayout}
                      path="/privacy-policy"
                      component={PrivacyPolicy}
                    />
                    {/* <RouteWithLayout layout={EmptyLayout} path="/car/:id" component={SingleCar} /> */}
                    <RouteWithLayout
                      layout={EmptyLayout}
                      path="/car/:id"
                      component={SingleCarnew}
                    />
                    {/* <RouteWithLayout layout={EmptyLayout} path="/single-vehicle/:id" component={SingleVehicle} /> */}
                    {/* <RouteWithLayout layout={EmptyLayout} path="/registration" component={Registration} /> */}
                    {/* <RouteWithLayout layout={EmptyLayout} path="/vehicle-lists" component={VehicleList} /> */}
                    <RouteWithLayout
                      layout={EmptyLayout}
                      path="/used-cars/:location?/:brand?/:model?"
                      component={UsedCars}
                    />
                    {/* <RouteWithLayout layout={EmptyLayout} path="/used-cars2/:location?/:brand?/:model?" component={UsedCars} /> */}
                    <RouteWithLayout
                      layout={EmptyLayout}
                      path="/dealer-signup"
                      component={DealerSignup}
                    />
                    <RouteWithLayout
                      layout={EmptyLayout}
                      path="/car-sourcing"
                      component={CarSourcing}
                    />
                    <RouteWithLayout
                      layout={EmptyLayout}
                      path="/thankyou"
                      component={Thankyou}
                    />
                    {/* <RouteWithLayout layout={EmptyLayout} path="/vehicle-lists-new" component={VehicleListnew} /> */}
                  </Switch>
                ) : (
                  ""
                )
              ) : (
                ""
              )
            ) : (
              <Switch>
                <RouteWithLayout
                  exact={true}
                  layout={EmptyLayout}
                  path="/"
                  component={HomePage}
                />
                <RouteWithLayout
                  layout={EmptyLayout}
                  path="/faq"
                  component={Faq}
                />
                <RouteWithLayout
                  layout={EmptyLayout}
                  path="/about-us"
                  component={About}
                />
                <RouteWithLayout
                  layout={EmptyLayout}
                  path="/blog/:slug?"
                  component={Blog}
                />
                <RouteWithLayout
                  layout={EmptyLayout}
                  path="/contact"
                  component={Contact}
                />
                {/* <RouteWithLayout layout={EmptyLayout} path="/payment" component={Payment} /> */}
                <RouteWithLayout
                  layout={EmptyLayout}
                  path="/terms-and-conditions"
                  component={TermCondition}
                />
                <RouteWithLayout
                  layout={EmptyLayout}
                  path="/privacy-policy"
                  component={PrivacyPolicy}
                />
                <RouteWithLayout
                  layout={EmptyLayout}
                  path="/car/:id"
                  component={SingleCar}
                />
                {/* <RouteWithLayout layout={EmptyLayout} path="/single-vehicle/:id" component={SingleVehicle} /> */}
                {/* <RouteWithLayout layout={EmptyLayout} path="/registration" component={Registration} /> */}
                {/* <RouteWithLayout layout={EmptyLayout} path="/vehicle-lists" component={VehicleList} /> */}
                <RouteWithLayout
                  layout={EmptyLayout}
                  path="/used-cars/:location?/:brand?/:model?"
                  component={UsedCars}
                />
                {/* <RouteWithLayout layout={EmptyLayout} path="/used-cars/:location?/:brand?/:model?" component={UsedCarsInProcess} /> */}
                {/* <RouteWithLayout layout={EmptyLayout} path="/used-cars2/:location?/:brand?/:model?" component={UsedCars} /> */}
                {/* <RouteWithLayout layout={EmptyLayout} path="/vehicle-lists-new" component={VehicleListnew} /> */}
                <RouteWithLayout
                  layout={EmptyLayout}
                  path="/sign-up"
                  component={Signup}
                />
                <RouteWithLayout
                  layout={EmptyLayout}
                  path="/sign-in"
                  component={SignIn}
                />
                <RouteWithLayout
                  layout={EmptyLayout}
                  path="/car-sourcing"
                  component={CarSourcing}
                />
                <RouteWithLayout
                  layout={EmptyLayout}
                  path="/dealer-signup"
                  component={DealerSignup}
                />
                <RouteWithLayout
                  layout={EmptyLayout}
                  path="/thankyou"
                  component={Thankyou}
                />
              </Switch>
            )}
            {/*
            is_loggedin
            ?
              userrole && userrole === '$aHF667#79+57h%45'
              ?
                <Switch>
                  <RouteWithLayout layout={AdminLayout} path="/leads" component={AdminLeadList} />
                  <RouteWithLayout layout={AdminLayout} path="/queries" component={AdminQueriesList} />
                  <RouteWithLayout layout={AdminLayout} path="/settings" component={AdminSettings} />
                  <RouteWithLayout layout={AdminLayout} path="/vehicle/:id" component={AdminSingleVehicle} />
                  <RouteWithLayout layout={AdminLayout} path="/pages" component={AdminPages} />
                  <RouteWithLayout layout={AdminLayout} path="/blogs" component={AdminBlogs} />
                  <RouteWithLayout layout={AdminLayout} path="/add-blog" component={AddBlog} />
                  <RouteWithLayout layout={AdminLayout} path="/edit-blog/:slug" component={EditBlog} />
                  <RouteWithLayout layout={AdminLayout} path="/edit-page/:slug" component={EditPage} />
                  <RouteWithLayout layout={AdminLayout} path="/faqs" component={AdminFaqs} />
                  <RouteWithLayout layout={AdminLayout} path="/add-faq" component={AddFaq} />
                  <RouteWithLayout layout={AdminLayout} path="/edit-faq/:slug" component={EditFaq} />
                  <RouteWithLayout layout={AdminLayout} path="/dashboard" component={Dashboard} />
                  <RouteWithLayout exact={true} layout={EmptyLayout} path="/" component={HomePage} />
                  <RouteWithLayout layout={EmptyLayout} path="/faq" component={Faq} />
                  <RouteWithLayout layout={EmptyLayout} path="/about-us" component={About} />
                  <RouteWithLayout layout={EmptyLayout} path="/blog/:slug?" component={Blog} />
                  <RouteWithLayout layout={EmptyLayout} path="/contact" component={Contact} />
                  <RouteWithLayout layout={EmptyLayout} path="/terms-and-conditions" component={TermCondition} />
                  <RouteWithLayout layout={EmptyLayout} path="/privacy-policy" component={PrivacyPolicy} />
                  <RouteWithLayout layout={EmptyLayout} path="/car/:id" component={SingleCar} />
                  <RouteWithLayout layout={EmptyLayout} path="/single-vehicle/:id" component={SingleVehicle} />
                  <RouteWithLayout layout={EmptyLayout} path="/registration" component={Registration} />
                  <RouteWithLayout layout={EmptyLayout} path="/vehicle-lists" component={VehicleList} />
                  <RouteWithLayout layout={EmptyLayout} path="/used-cars/:location?/:brand?/:model?" component={UsedCars} />      
                  <RouteWithLayout layout={EmptyLayout} path="/vehicle-lists-new" component={VehicleListnew} />
                </Switch>
              :
                <Switch>
                  <RouteWithLayout exact={true} layout={EmptyLayout} path="/" component={HomePage} />
                  <RouteWithLayout layout={EmptyLayout} path="/faq" component={Faq} />
                  <RouteWithLayout layout={EmptyLayout} path="/about-us" component={About} />
                  <RouteWithLayout layout={EmptyLayout} path="/blog/:slug?" component={Blog} />
                  <RouteWithLayout layout={EmptyLayout} path="/contact" component={Contact} />
                  <RouteWithLayout layout={EmptyLayout} path="/terms-and-conditions" component={TermCondition} />
                  <RouteWithLayout layout={EmptyLayout} path="/privacy-policy" component={PrivacyPolicy} />
                  <RouteWithLayout layout={EmptyLayout} path="/car/:id" component={SingleCar} />
                  <RouteWithLayout layout={EmptyLayout} path="/single-vehicle/:id" component={SingleVehicle} />
                  <RouteWithLayout layout={EmptyLayout} path="/registration" component={Registration} />
                  <RouteWithLayout layout={EmptyLayout} path="/vehicle-lists" component={VehicleList} />
                  <RouteWithLayout layout={EmptyLayout} path="/used-cars/:location?/:brand?/:model?" component={UsedCars} />      
                  <RouteWithLayout layout={EmptyLayout} path="/vehicle-lists-new" component={VehicleListnew} />
                </Switch>
            :
              <Switch>
                <RouteWithLayout exact={true} layout={EmptyLayout} path="/" component={HomePage} />
                <RouteWithLayout layout={EmptyLayout} path="/faq" component={Faq} />
                <RouteWithLayout layout={EmptyLayout} path="/about-us" component={About} />
                <RouteWithLayout layout={EmptyLayout} path="/blog/:slug?" component={Blog} />
                <RouteWithLayout layout={EmptyLayout} path="/contact" component={Contact} />
                <RouteWithLayout layout={EmptyLayout} path="/terms-and-conditions" component={TermCondition} />
                <RouteWithLayout layout={EmptyLayout} path="/privacy-policy" component={PrivacyPolicy} />
                <RouteWithLayout layout={EmptyLayout} path="/car/:id" component={SingleCar} />
                <RouteWithLayout layout={EmptyLayout} path="/single-vehicle/:id" component={SingleVehicle} />
                <RouteWithLayout layout={EmptyLayout} path="/registration" component={Registration} />
                <RouteWithLayout layout={EmptyLayout} path="/vehicle-lists" component={VehicleList} />
                <RouteWithLayout layout={EmptyLayout} path="/used-cars/:location?/:brand?/:model?" component={UsedCars} />      
                <RouteWithLayout layout={EmptyLayout} path="/vehicle-lists-new" component={VehicleListnew} />
                <RouteWithLayout layout={EmptyLayout} path="/sign-up" component={Signup} />
                <RouteWithLayout layout={EmptyLayout} path="/sign-in" component={SignIn} />                                
              </Switch>
          */}
          </ScrollToTop>
        </BrowserRouter>
      </HttpsRedirect>
    );
  }
}
export default App;
