import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getfiltersnew } from '../store/actions/CarActions';
import { gethomepage,getpage } from '../store/actions/CommonActions';
import { getfilterdyear,getfilterdmake ,getfilterdmodel,getfilterdfuel,getfilterdcondition,getfilterdbodystyles,getfilterdMileage,getfilterdtransmission,getfilterdengine,getfilterdcolor} from '../store/actions/FilterActions';
import {Helmet} from "react-helmet";
import renderHTML from 'react-render-html';
import SimpleReactValidator from 'simple-react-validator';

class HomePage extends Component {
  constructor(props){
    super(props);
    this.formValidator = new SimpleReactValidator();
    this.state = {
      Year:"",
      Make:"",
      Model:"",
      Fuel:"",
      body_style:"",
      Condition:"",
      Mileage:"",
      transmission_type:"",
      engine:"",
      color:[],
      filterdata:"",
      Make:"",
      premium_car:0,
      is_manheim_car:0,
      makefilter: '',
      fuelfilter: '',
      bodystylefilter: '',
      transmissionfilter: '',
      Fuel:"",
      modelfilter:'',
      minYear:'',
      maxYear:'',
      minPrice:'',
      maxPrice:'',
      minMileage:'',
      maxMileage:'',
      page:'',
      homepagedata:'',
      showvideo:false
    }
    this.handleOnload = this.handleOnload.bind(this);
    this.loadDone = this.loadDone.bind(this);
    this.wrapperRef = React.createRef();
  }
  componentDidMount(){
    var slug = 'homepage';
    this.props.dispatch(getpage(slug));
    this.props.dispatch(gethomepage(slug));
    // Filters update
    let {is_manheim_car,premium_car,minPrice,maxPrice,minYear,maxYear,Make,Model,Fuel,body_style,transmission_type,Condition,minMileage,maxMileage,engine,color} = this.state;
    this.props.dispatch(getfilterdmake(is_manheim_car,premium_car,minPrice,maxPrice,minYear,maxYear,Make,Model,Fuel,body_style,Condition,minMileage,maxMileage,transmission_type,engine,color));
    this.props.dispatch(getfilterdfuel(is_manheim_car,premium_car,minPrice,maxPrice,minYear,maxYear,Make,Model,Fuel,body_style,Condition,minMileage,maxMileage,transmission_type,engine,color));
    this.props.dispatch(getfilterdtransmission(is_manheim_car,premium_car,minPrice,maxPrice,minYear,maxYear,Make,Model,Fuel,body_style,Condition,minMileage,maxMileage,transmission_type,engine,color));
    // this.props.dispatch(getfilterdbodystyles(minPrice,maxPrice,minYear,maxYear,Make,Model,Fuel,body_style,Condition,minMileage,maxMileage,transmission_type,engine,color));
    // this.props.dispatch(getfilterdmodel(minPrice,maxPrice,minYear,maxYear,Make,Model,Fuel,body_style,Condition,minMileage,maxMileage,transmission_type,engine,color));
    // Filters update
    document.addEventListener('scroll', this.handleOnload);
    document.addEventListener('DOMContentLoaded', this.loadDone);
  }
  componentDidUpdate() {
      
  }
  handleOnload(event) {
    // this.setState({
    //   showvideo:true
    // })
    if (this.wrapperRef && this.wrapperRef.current) {
      this.setState({
        showvideo:true
      })
    }
  }
  loadDone(e){
    console.log('dsadasdasdsd')
  }
  componentWillReceiveProps(nextProps){
    this.setState({
      page:nextProps.page,
      homepagedata:nextProps.homepagedata,
      makefilter:nextProps.makedata,
      fuelfilter:nextProps.fueldata,
      bodystylefilter:nextProps.bodystyledata,
      transmissionfilter:nextProps.transmissiondata,
      modelfilter:nextProps.modeldata,
    })
  }
  onfilterChange = (ev) => {		
    this.setState({
      [ev.target.name]:ev.target.value
    })
  }
  search = () => {
		let validate = this.formValidator;
    if(validate.allValid()){
      const {Model,Make,Fuel,transmission_type,body_style} = this.state;
      var url = '/used-cars?filter=true&Make='+Make+'&Model='+Model+'&Fuel='+Fuel+'&transmission_type='+transmission_type+'&body_style='+body_style;
      this.props.history.push(url);

    }else{
      validate.showMessages();
      this.forceUpdate();
    }
  }
  // filterSelect = (name) => {
  //   let {minPrice,maxPrice,minYear,maxYear,Make,Model,Fuel,body_style,transmission_type,Condition,minMileage,maxMileage,engine,color} = this.state;
  //   if(name === 'Make'){
  //     this.props.dispatch(getfilterdmake(minPrice,maxPrice,minYear,maxYear,Make,Model,Fuel,body_style,Condition,minMileage,maxMileage,transmission_type,engine,color));
  //   }else if(name === 'Fuel'){
  //     this.props.dispatch(getfilterdfuel(minPrice,maxPrice,minYear,maxYear,Make,Model,Fuel,body_style,Condition,minMileage,maxMileage,transmission_type,engine,color));
  //   }else if(name === 'transmission_type'){
  //     this.props.dispatch(getfilterdtransmission(minPrice,maxPrice,minYear,maxYear,Make,Model,Fuel,body_style,Condition,minMileage,maxMileage,transmission_type,engine,color));
  //   }else if(name === 'body_style'){
  //     this.props.dispatch(getfilterdbodystyles(minPrice,maxPrice,minYear,maxYear,Make,Model,Fuel,body_style,Condition,minMileage,maxMileage,transmission_type,engine,color));
  //   }else if(name === 'Model'){
  //     this.props.dispatch(getfilterdmodel(minPrice,maxPrice,minYear,maxYear,Make,Model,Fuel,body_style,Condition,minMileage,maxMileage,transmission_type,engine,color));
  //   }
  // }
  selectOption = (name,value) => {
    this.setState({
      [name]:value
    })
    let {is_manheim_car,premium_car,minPrice,maxPrice,minYear,maxYear,Make,Model,Fuel,body_style,transmission_type,Condition,minMileage,maxMileage,engine,color} = this.state; 
    if(name === 'Make'){
      Make = value;
    }
    if(name === 'Model'){
      Model = value;
    }
    if(name === 'Fuel'){
      Fuel = value;
    }
    if(name === 'body_style'){
      body_style = value;
    }
    if(name === 'transmission_type'){
      transmission_type = value;
    }
    if(name !== 'Make' && !Make){
      this.props.dispatch(getfilterdmake(is_manheim_car,premium_car,minPrice,maxPrice,minYear,maxYear,Make,Model,Fuel,body_style,Condition,minMileage,maxMileage,transmission_type,engine,color));
    }
    if(name !== 'Model' && !Model){
      this.props.dispatch(getfilterdmodel(is_manheim_car,premium_car,minPrice,maxPrice,minYear,maxYear,Make,Model,Fuel,body_style,Condition,minMileage,maxMileage,transmission_type,engine,color));
    }
    if(name !== 'Fuel' && !Fuel){
      this.props.dispatch(getfilterdfuel(is_manheim_car,premium_car,minPrice,maxPrice,minYear,maxYear,Make,Model,Fuel,body_style,Condition,minMileage,maxMileage,transmission_type,engine,color));
    }
    if(name !== 'body_style' && !body_style){
      this.props.dispatch(getfilterdbodystyles(is_manheim_car,premium_car,minPrice,maxPrice,minYear,maxYear,Make,Model,Fuel,body_style,Condition,minMileage,maxMileage,transmission_type,engine,color));
    }
    if(name !== 'transmission_type' && !transmission_type){
      this.props.dispatch(getfilterdtransmission(is_manheim_car,premium_car,minPrice,maxPrice,minYear,maxYear,Make,Model,Fuel,body_style,Condition,minMileage,maxMileage,transmission_type,engine,color));
    }
  }
  clearOption = (name) => {
    let {is_manheim_car,premium_car,minPrice,maxPrice,minYear,maxYear,Make,Model,Fuel,body_style,transmission_type,Condition,minMileage,maxMileage,engine,color} = this.state; 

    this.setState({
      [name]:''
    })
    if(name === 'Make'){
      this.setState({
        Model:'',
        body_style:''
      })  
    }
    if(name === 'Model'){
      this.setState({
        body_style:''
      })  
    }
    if(name === 'Make'){
      Make = '';
    }
    if(name === 'Model'){
      Model = '';
    }
    if(name === 'Fuel'){
      Fuel = '';
    }
    if(name === 'body_style'){
      body_style = '';
    }
    if(name === 'transmission_type'){
      transmission_type = '';
    }
    this.props.dispatch(getfilterdmake(is_manheim_car,premium_car,minPrice,maxPrice,minYear,maxYear,Make,Model,Fuel,body_style,Condition,minMileage,maxMileage,transmission_type,engine,color));
    this.props.dispatch(getfilterdmodel(is_manheim_car,premium_car,minPrice,maxPrice,minYear,maxYear,Make,Model,Fuel,body_style,Condition,minMileage,maxMileage,transmission_type,engine,color));
    this.props.dispatch(getfilterdfuel(is_manheim_car,premium_car,minPrice,maxPrice,minYear,maxYear,Make,Model,Fuel,body_style,Condition,minMileage,maxMileage,transmission_type,engine,color));
    this.props.dispatch(getfilterdbodystyles(is_manheim_car,premium_car,minPrice,maxPrice,minYear,maxYear,Make,Model,Fuel,body_style,Condition,minMileage,maxMileage,transmission_type,engine,color));
    this.props.dispatch(getfilterdtransmission(is_manheim_car,premium_car,minPrice,maxPrice,minYear,maxYear,Make,Model,Fuel,body_style,Condition,minMileage,maxMileage,transmission_type,engine,color));
  }
  render() {
    const {homepagedata,page,modelfilter,Model,makefilter,fuelfilter,bodystylefilter,transmissionfilter,filterdata,Make,Fuel,body_style,transmission_type} = this.state;
    // console.log('makefilter'+makefilter)  
    // console.log('fuelfilter'+fuelfilter)  
    return (
          <>
              <Helmet>
              <title>Leading Irish Importer of Quality UK Used Cars - UK Car Imports</title>
              <meta name="title" content="Leading Irish Importer of Quality UK Used Cars - UK Car Imports" />
              <meta name="description" content="Safe and easy way to buy UK used cars from Ireland - VRT &amp; NOx fees due per car. Optional mechanical &amp; condition inspection reports. Optional warranty cover &amp; VRT processing. Let our professionals do the work - UK Car Imports" />
              </Helmet>
              <section className="homepage_top_banner">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6">
                      <h2 className="text-uppercase find-car">Let's Find A Car For You</h2>                          
                    </div>
                      <div className="col-md-6">
                        <div className="vehicle_filter">
                        <h3 className="mb-2">Start Your Search Here</h3>
                        <div className="inner_filter">
                          <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <div className="dropdown">
                                  {/* <button className="btn btn-secondary dropdown-toggle" onClick={e => this.filterSelect('Make')} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> */}
                                  <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                      {Make ? <span className="selected">{Make}</span> : 'Make'}
                                  </button>
                                  {Make ? 
                                      <a className="inbtn" onClick={e => this.clearOption('Make')}><i className="fa fa-times"></i></a>
                                      : 
                                  '' }
                                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    {
                                      this.props.makeloading
                                      ?
                                        <a className="dropdown-item"><i className="fa-li fa fa-spinner fa-spin"></i></a>
                                        
                                      :
                                        makefilter.make 
                                        ? 
                                          makefilter.make.map((item,key) => (
                                              <a key={key} className="dropdown-item" onClick={e => this.selectOption(`Make`,item.make)} >{item.make}  ({item.total})</a>
                                          ))
                                        :
                                          <a className="dropdown-item" ><i className="fa-li fa fa-spinner fa-spin"></i></a>
                                    }
                                  </div>
                                  { this.formValidator.message('Make', this.state.Make ,'required') }								                                                          
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <div className="dropdown">
                                  {/* <button className="btn btn-secondary dropdown-toggle" onClick={e => this.filterSelect('Model')} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" disabled={Make ? false : true}> */}
                                  <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" disabled={Make ? false : true}>
                                      {Model ? <span className="selected">{Model}</span> : 'Model'}
                                  </button>
                                  {
                                    Model ? 
                                      <a className="inbtn" onClick={e => this.clearOption('Model')}><i className="fa fa-times"></i></a>
                                    : 
                                      '' 
                                  }
                                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    {
                                    this.props.modelloading
                                    ?
                                        <a className="dropdown-item" ><i className="fa-li fa fa-spinner fa-spin"></i></a>
                                        
                                    :
                                        modelfilter.model 
                                        ? 
                                          modelfilter.model.map((item) => (
                                              <a className="dropdown-item" onClick={e => this.selectOption(`Model`,item.car_model)} >{item.car_model}  ({item.total})</a>
                                          ))
                                        :
                                          <a className="dropdown-item" ><i className="fa-li fa fa-spinner fa-spin"></i></a>
                                    }
                                  </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <div className="dropdown">
                                  {/* <button className="btn btn-secondary dropdown-toggle" onClick={e => this.filterSelect('body_style')} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" disabled={Model ? false : true}> */}
                                  <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" disabled={Model ? false : true}>
                                      {body_style ? <span className="selected">{body_style}</span> : 'Body Type'}
                                  </button>
                                  {body_style ? 
                                      <a className="inbtn" onClick={e => this.clearOption('body_style')}><i className="fa fa-times"></i></a>
                                      : 
                                  '' }
                                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                  {
                                    this.props.bodystyleloading
                                    ?
                                      <a className="dropdown-item" ><i className="fa-li fa fa-spinner fa-spin"></i></a>
                                    :
                                    bodystylefilter.body_style 
                                        ? 
                                        bodystylefilter.body_style.map((item) => (
                                            <a className="dropdown-item" onClick={e => this.selectOption(`body_style`,item.body_style)} >{item.body_style}  ({item.total})</a>
                                          ))
                                        :
                                          <a className="dropdown-item" ><i className="fa-li fa fa-spinner fa-spin"></i></a>
                                  }
                                  </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <div className="dropdown">
                                  {/* <button className="btn btn-secondary dropdown-toggle" onClick={e => this.filterSelect('Fuel')} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> */}
                                  <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                      {Fuel ? <span className="selected">{Fuel}</span> : 'Fuel Type'}
                                  </button>
                                  {Fuel ? 
                                      <a className="inbtn" onClick={e => this.clearOption('Fuel')}><i className="fa fa-times"></i></a>
                                      : 
                                  '' }
                                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                  {
                                    this.props.fuelloading
                                    ?
                                      <a className="dropdown-item" ><i className="fa-li fa fa-spinner fa-spin"></i></a>
                                    :
                                      fuelfilter.fuel_type 
                                      ? 
                                        fuelfilter.fuel_type.map((item,key) => (
                                              <a key={key} className="dropdown-item" onClick={e => this.selectOption(`Fuel`,item.fuel_type)}>{item.fuel_type}  ({item.total})</a>
                                          ))
                                      :
                                        <a className="dropdown-item" ><i className="fa-li fa fa-spinner fa-spin"></i></a>
                                  }
                                  </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="dropdown">
                                  {/* <button className="btn btn-secondary dropdown-toggle" onClick={e => this.filterSelect('transmission_type')} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> */}
                                  <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                      {transmission_type ? <span className="selected">{transmission_type}</span> : 'GearBox'}
                                  </button>
                                  {transmission_type ? 
                                      <a className="inbtn" onClick={e => this.clearOption('transmission_type')}><i className="fa fa-times"></i></a>
                                      : 
                                  '' }
                                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                  {
                                    this.props.transmissionloading
                                    ?
                                      <a className="dropdown-item"><i className="fa-li fa fa-spinner fa-spin"></i></a>
                                    :
                                      transmissionfilter.transmission 
                                        ? 
                                          transmissionfilter.transmission.map((item,key) => (
                                            // console.log(item+' : '+key)
                                            <a key={key} className="dropdown-item" onClick={e => this.selectOption(`transmission_type`,item.car_transmission)}>{item.car_transmission}  ({item.total})</a>
                                          ))
                                        :
                                          <a className="dropdown-item"><i className="fa-li fa fa-spinner fa-spin"></i></a>
                                  }
                                  </div>
                              </div>
                            </div>
                            
                          </div>
                          <div className="col-md-12 views">
                            <button type="button" className="btn btn-danger" onClick={e => this.search()}>Search Vehicles</button>
                          </div>
                        </div>      
                        </div>
                        </div>
                        </div>
                    </div>
                  </div>    
              </section>
              {
                homepagedata.aboutus
                ?
                  renderHTML(homepagedata.aboutus)
                :
                  ''                  
              }
              {
                homepagedata.howitworks
                ?
                  <section ref={this.wrapperRef} className="how-it-works py-5">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <h2 className="mb-2">HOW IT WORKS?</h2>
                          {this.state.showvideo ? renderHTML(homepagedata.howitworks) : ''}
                        </div>
                      </div>
                    </div>
                  </section>
                :
                  ''
              }
              {
                homepagedata.ourtrade
                ?
                  <section className="how-it-works py-4">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <h2 className="mb-2">Our Trade In Service</h2>
                          {this.state.showvideo ? renderHTML(homepagedata.ourtrade) : ''}
                        </div>
                      </div>
                    </div>
                  </section>
                :
                  ''
                  
              }
              {
                homepagedata.whyus
                ?
                  renderHTML(homepagedata.whyus)
                :
                  ''
                  
              }
              {/* {
                // page.content == ''
                page.content == ''
                ?
                  renderHTML(page.content)  
                :
                  <>
                    <section className="about-us">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-6">
                          
                          </div>
                          <div className="col-md-6 mt-4 pl-5">
                            <h2>About UK CAR IMPORTS</h2>
                            <h6>Quality, Value and Choice in used cars from the UK</h6>
                            <p>With UK Car Imports you can choose your new car from over 300,000 used UK vehicles on our website. We remain the only company in Ireland to provide accurate VRT estimates for every used car on our site. This means you avoid costly mistakes when calculating VRT. Our inspection service is bespoke and centred on our customer. We work on your behalf to protect your investment. 
                              <b>Please be advised that Irish Revenue do not provide NOx VRT estimates as they do for CO2 VRT. We will only manually check VRT where VRT processing is selected in the services menu when you click on ‘Place a Deposit’. NOx VRT due is variable and while NOx VRT estimates post 2015 are representative, from 2014 backwards the estimates may be incorrect. We accept no liability for them you should confirm all VRT elements for yourself prior to purchasing.  </b>
                            </p>
                          </div>

                          </div>
                          </div>
                    </section>
                    <section ref={this.wrapperRef} className="how-it-works py-5">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12 text-center">
                            <h2 className="mb-2">HOW IT WORKS?</h2>
                            {this.state.showvideo ? <iframe src="https://www.youtube.com/embed/L-2oXvn2oko" width="100%" height="500px" frameBorder="0" allowFullScreen="allowFullScreen"></iframe> : ''}
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="how-it-works py-4">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12 text-center">
                            <h2 className="mb-2">Our Trade In Service</h2>
                            {this.state.showvideo ? <iframe src="https://www.youtube.com/embed/2HwE_KZucIM" width="100%" height="500px" frameBorder="0" allowFullScreen="allowFullScreen"></iframe> : ''}
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="how-it-works imports py-5">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12 text-center why_us">
                          <h2>WHY UK CAR IMPORTS?</h2>
                          <h6 className="">GLAD YOU ASKED</h6>
                          </div>
                          <div className="row mt-5">
                            <div className="col-md-4 why_each">
                              <img src="assets/images/tick.png" />
                              <h3>PAY LESS</h3>
                              <p>Pay 20% less. Our low cost set up means you pay less than at your local garage. Removing the cost of holding stock and a large car showroom reduces our overheads and means we can charge much less. Save thousands of euro when using our service.</p>
                            </div>
                            <div className="col-md-4 why_each">
                              <img src="assets/images/tick.png" />
                              <h3>SAFE</h3>
                              <p>With more than 1,000 cars purchased for clients over 6 years our service is tried and trusted, we are ranked no.1 on Google for our service and remain the premier importer of UK cars into Ireland. Furthermore we guarantee mechanical and condition reports to assure your vehicle will be as described on collection.</p>
                            </div>
                            <div className="col-md-4 why_each">
                              <img src="assets/images/tick.png" />
                              <h3>MORE CHOICE</h3>
                              <p>With a limited choice in colours and specifications on the Irish market you can increase the choice on offer to as many as 300,000 used cars from across the UK</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4 why_each">
                              <img src="assets/images/tick.png" />
                              <h3>NICE & EASY</h3>
                              <p>From providing a guaranteed inspection report to managing the purchase and on to having your vehicle transported to Dublin with optional VRT processing or warranty, we do everything for you. Just choose the car you want & the services you require, sit back and let us do the work!</p>
                            </div>
                            <div className="col-md-4 why_each">
                              <img src="assets/images/tick.png" />
                              <h3>TRADE IN SERVICE </h3>
                              <p>Most need to sell their old car before buying a new one. UK garages do not take Irish trade ins so our service is the only one of its kind, allowing Irish buyers to trade in their car against UK used imports.</p>
                            </div>
                            <div className="col-md-4 why_each">
                              <img src="assets/images/tick.png" />
                              <h3>PURCHASE MANAGER SERVICE
                              </h3>
                              <p>With about 300,000 used cars online daily we provide the largest selection of used cars to the Irish market. However, if you find a vehicle elsewhere than ukcarimports.ie and would like us to manage the purchase then we can help. Our fee to manage the purchase with the UK garage, on your behalf, provide a mechanical inspection and history check, transport your new vehicle to Dublin and complete the VRT registration is €1,800.</p>
                            </div>
                          </div> 
                        </div>
                      </div>
                  </section>
                  </>
              } */}
              <div className="reviews">
                <img width="200" height="60" src="assets/images/Reviewsicon.webp" />see what other are saying about us on google
              </div>
              <div className="footer_new">
                <div className="left_half_box">
                  <h2>OUR OFFICES</h2>
                  <div className="footer_box">
                    <div className="left_foot_box">
                      <img width="60" height="60"  src="assets/images/icon-4.png" />
                    </div>
                    <div className="right_foot_box">
                      <h5>ADDRESS</h5>
                      <p>51 Bracken Rd, Sandyford Business Park, Sandyford, Dublin, D18 CV48, Ireland</p>
                    </div>
                  </div>
                  <div className="footer_box">
                    <div className="left_foot_box">
                      <img width="60" height="60" src="assets/images/icon-5.png" />
                    </div>
                    <div className="right_foot_box">
                      <h5>EMAIL</h5>
                      <p>info@ukcarimports.ie</p>
                    </div>
                  </div>
                  
                </div>
                <div className="right_half_box">
                  {
                    this.state.showvideo 
                      ? 
                        <iframe style={{border: "0"}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2385.907439518616!2d-6.218018634164293!3d53.27327807996377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4867054677fba92f%3A0x23d83dd2dc00eb6e!2sUK%20Car%20Imports!5e0!3m2!1sen!2sin!4v1589012063630!5m2!1sen!2sin" width="100%" height="500" frameBorder="0" allowFullScreen="allowFullScreen" aria-hidden="false"></iframe>
                      :
                        ''
                  }
                </div>
              </div>
          </>
      );
  }
}

const mapStateToProps = (state) => ({
    carsdata: state.car.carsdatanew,
    carsloading: state.car.carsloadingnew,
    filteredcardata: state.car.filteredcardatanew,
    filteredcarloading: state.car.filteredcarloadingnew,
    filterloading: state.car.filterloadingnew,
    filterdata: state.car.filterdatanew,
    filtermodelloading: state.car.filtermodelloading,
    filtermodeldata: state.car.filtermodeldata,
    filterbodystyleloading: state.car.filterbodystyleloading,
    filterbodystyledata: state.car.filterbodystyledata,

    makedata: state.filter.makesdata,
    bodystyledata:state.filter.bodystyledata,
    modeldata: state.filter.modelsdata,
    fueldata:state.filter.fueldata,
    transmissiondata:state.filter.transmissiondata,

    makeloading: state.filter.makesloading,
    modelloading: state.filter.modelsloading,
    fuelloading:state.filter.fuelloading,
    bodystyleloading:state.filter.bodystyleloading,
    transmissionloading:state.filter.transmissionloading,

    page: state.common.page,
    pageloading: state.common.pageloading,
    homepagedata: state.common.homepagedata,
    homepageloading: state.common.homepageloading,
})
export default connect(mapStateToProps)(HomePage);
// export default HomePage;