import { combineReducers } from 'redux';
import userReducer from './userReducer';
import carReducer from './CarReducer';
import commonReducer from './CommonReducer';
import FilterReducer from './FilterReducer';
export default combineReducers({
    user:userReducer,
    car:carReducer,
    common:commonReducer,
    filter:FilterReducer
});