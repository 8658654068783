//Live API base
export const apiBaseUrl = "https://api.ukcarimports.ie/public";
//Stagging API baseurl
// export const apiBaseUrl = "https://api.selima20.sg-host.com/public";

export function gameName(gname) {
  if (gname === "overwatch") {
    return "OVERWATCH";
  } else {
    return "";
  }
}
export default function parseJwt(token) {
  if (!token) {
    return;
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
}

// export default function userAuth(){
//   if (localStorage.getItem('token')) {
//       const currdetails = parseJwt(localStorage.getItem('token'));
//       const currid = currdetails.sub;
//       return currid;
//   }else{
//     window.location.reload('/');
//   }
// }
