import React, { Component } from 'react';
import Header from './Header';

class CompleteEmptyLayout extends Component {
    render() {
        return (
            <>
             <Header />
             {/* <div className="container"> */}
                    <div className="row">
                {this.props.children}
            </div>
            {/* </div> */}
            </>
        );
    }
}

export default CompleteEmptyLayout;